<template>
  <div>
    <div v-loading="loading">
      <!--步骤2-->
      <div class="content-goods-publish">
        <!--商品详情-->
        <el-form :model="baseInfoForm" status-icon :rules="baseInfoFormRule" label-position="right" ref="baseInfoForm"
          label-width="120px" class="demo-ruleForm">
          <!--商品信息-->
          <div class="base-info-item">
            <h4>商品信息</h4>
            <div>
              <el-form-item label="商品名称" prop="goods_name" class="goods-name-width">
                <el-input v-model="baseInfoForm.goods_name" :maxlength="60" :minlength="3" placeholder="3-60个字符"
                  disabled></el-input>
              </el-form-item>
              <el-form-item label="商品品牌：">
                <el-select disabled v-model="baseInfoForm.ext_brand_id" filterable clearable placeholder="请选择">
                  <el-option v-for="item in brandList" :key="item.brand_id" :label="item.name" :value="item.brand_id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商品图片" prop="goods_gallery" style="width: 90%;text-align: left;">
                <el-upload class="avatar-uploader goods-images" :action="`${MixinUploadApi}?scene=goods`"
                  list-type="picture-card" multiple :on-exceed="handleExceed" :file-list="goodsGalleryList"
                  :on-preview="handlePictureCardPreview" :limit="5" :before-upload="beforeAvatarUpload" :on-remove="handleRemove"
                  :on-success="handleSuccess">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">
                    建议尺寸：640*640像素，大小不超1MB，可拖拽调整位置，最多上传5张图。
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogImage">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <el-button :loading="createGoodsButtonLoading" type="primary" size="small" @click="aboveGoods">确认编辑</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as API_goods from "@/api/goods";
import * as API_goodsCategory from "@/api/goodsCategory";
import { CategoryPicker, SkuSelector, UE } from "@/components";
import { RegExp } from "@/../ui-utils";
import Sortable from "sortablejs";
export default {
  name: "goodsPublish",
  components: {
    [CategoryPicker.name]: CategoryPicker,
    [SkuSelector.name]: SkuSelector,
    [UE.name]: UE
  },
  computed: {
    ...mapGetters(["shopInfo"])
  },
  data () {
    const checkSn = (rule, value, callback) => {
      if (!value) {
        callback(new Error("商品编号不能为空"));
      } else if (!/^[a-zA-Z0-9_\\-]+$/g.test(value)) {
        callback(new Error("请输入数字、字母、下划线或者中划线"));
      } else {
        callback();
      }
    };

    const checkMarket = (rule, value, callback) => {
      if (!value) {
        callback(new Error("市场价格不能为空"));
      } else if (value == 0) {
        callback(new Error("市场价格必须大于零"));
      } else if (!RegExp.money.test(value)) {
        callback(new Error("请输入正整数或者两位小数"));
      } else if (parseFloat(value) < parseFloat(this.baseInfoForm.cost)) {
        callback(new Error("市场价格必须大于等于供货价格"));

      } else {
        callback();
      }
    };

    const checkPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("会员价格不能为空"));
      }
      setTimeout(() => {
        if (!RegExp.money.test(value)) {
          callback(new Error("请输入正整数或者两位小数"));
        } else if (value == 0) {
          callback(new Error("会员价格必须大于零"));
        } else if (parseFloat(value) > 99999999) {
          callback(new Error("价格设置超过上限值"));
        } else {
          callback();
        }
      }, 1000);
    };
    // 成本价 - > 供货价
    const checkCost = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("供货价格不能为空"));
      } else if (value == 0) {
        callback(new Error("供货价格必须大于零"));
      }
      if (!RegExp.money.test(value)) {
        callback(new Error("请输入数字值"));
      } else {
        if (parseFloat(value) > parseFloat(this.baseInfoForm.mktprice)) {
          callback(new Error("市场价格必须大于等于供货价格"));
        } else {
          callback();
        }
      }
    };
    //  集采价
    const checkOriginal = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("集采价格不能为空"));
      }
      setTimeout(() => {
        if (!RegExp.money.test(value)) {
          callback(new Error("请输入正整数或者两位小数"));
        } else if (value == 0) {
          callback(new Error("集采价格必须大于零"));
        } else if (parseFloat(value) > 99999999) {
          callback(new Error("价格设置超过上限值"));
        } else if (parseFloat(value) > parseFloat(this.baseInfoForm.cost)) {
          callback(new Error("集采价格必须小于等于供货价"));
        } else {
          callback();
        }
      }, 1000);
    };

    const checkWeight = (rule, value, callback) => {
      if (!value) {
        callback(new Error("重量不能为空"));
      } else if (value <= 0) {
        callback(new Error("重量必须大于零"));
      } else if (!RegExp.three.test(value)) {
        callback(new Error("请输三位小数"));
      } else {
        callback();
      }
    };

    const checkTplId = (rule, value, callback) => {
      if (this.baseInfoForm.goods_transfee_charge === 0 && !value) {
        return callback(new Error("请选择一种模板"));
      } else {
        callback();
      }
    };

    // 总库存
    const checkQuantity = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error("请填写总库存"));
      }
      setTimeout(() => {
        if (!RegExp.integer0.test(value)) {
          callback(new Error("请输入正整数"));
        } else {
          if (parseInt(value) > 99999999) {
            callback(new Error("总库存不得大于99999999"));
          } else {
            callback();
          }
        }
      }, 300);
    };

    const checkExchangePoint = (rule, value, callback) => {
      if (
        this.baseInfoForm.exchange.enable_exchange &&
        !this.baseInfoForm.exchange.exchange_point
      ) {
        return callback(new Error("积分值不能为空且不能为0"));
      }
      setTimeout(() => {
        if (!RegExp.integer.test(this.baseInfoForm.exchange.exchange_point)) {
          callback(new Error("请输入正整数"));
        } else {
          if (parseInt(this.baseInfoForm.exchange.exchange_point) <= 0) {
            callback(new Error("请输入大于0的积分值"));
          } else {
            callback();
          }
        }
      }, 1000);
    };

    const checkExchangeMoney = (rule, value, callback) => {
      if (
        this.baseInfoForm.exchange.exchange_money !== 0 &&
        !this.baseInfoForm.exchange.exchange_money
      ) {
        return callback(new Error("积分所需金额不能为空"));
      }
      setTimeout(() => {
        if (!RegExp.money.test(this.baseInfoForm.exchange.exchange_money)) {
          callback(new Error("请输入正确的金额"));
        } else {
          callback();
        }
      }, 1000);
    };

    const checkGoodsGallery = (rule, value, callback) => {
      setTimeout(() => {
        if (!this.baseInfoForm.goods_gallery) {
          callback(new Error("请选择商品相册"));
        } else {
          callback();
        }
      }, 1000);
    };

    return {

      /** 加载中。。。 */
      loading: false,

      /** 当前状态/模式 默认发布商品0 编辑商品1 编辑草稿箱商品2 */
      currentStatus: 0,

      /** 当前点击的1级分类索引*/
      activeCategoryIndex1: -1,

      /** 当前点击的2级分类索引*/
      activeCategoryIndex2: -1,

      /** 当前点击的3级分类索引*/
      activeCategoryIndex3: -1,

      /** 当前商品分类名称1*/
      activeCategoryName1: "",

      /** 当前商品分类名称2*/
      activeCategoryName2: "",

      /** 当前商品分类名称3*/
      activeCategoryName3: "",

      /** 当前商品id*/
      activeGoodsId: "",

      /** 1级分类列表*/
      categoryListLevel1: [],

      /** 2级分类列表*/
      categoryListLevel2: [],

      /** 3级分类列表*/
      categoryListLevel3: [],

      /** 商品详情信息提交表单 */
      baseInfoForm: {
        /** 品牌id */
        ext_brand_id: "",

        /** 商城分类id */
        category_id: 0,

        /** 商城分类 名称 */
        category_name: "",

        /** 商品名称 */
        goods_name: "",

        /** 商品编号 sn*/
        // sn: '',

        /** 会员价格 */
        price: "",

        /** 市场价格 */
        mktprice: "",

        /** 成本价格 // 成本价 - > 供货价 */
        cost: "",

        /** 集采价 */
        original_price: "",

        /** 商品重量 */
        weight: 0,

        /** 商品相册列表 */
        goods_gallery_list: [],

        /** 用来校验的商品相册 */
        goods_gallery: "",

        /** 商品总库存 */
        quantity: 0,

        /** 卖家承担运费1 买家承担运费0 */
        goods_transfee_charge: 1,

        /** sku数据变化或者组合变化判断 0:没变化，1：变化 */
        has_changed: 0,

        /** 是否上架，1上架 0下架 */
        market_enable: 1,

        /** 模板运费id */
        template_id: "",

        /** 积分兑换 */
        exchange: {
          /** 积分兑换所属分类 */
          category_id: "",

          /** 是否允许积分兑换  1是 0否*/
          enable_exchange: 1,

          /** 兑换所需金额 */
          exchange_money: 0,

          /** 积分兑换使用的积分 */
          exchange_point: 0
        },

        /** 店铺分组id*/
        shop_cat_id: 0,

        /** seo描述 */
        meta_description: "",

        /** seo关键字 */
        meta_keywords: "",

        /** seo标题 */
        page_title: "",

        /** 商品参数列表 */
        goods_params_list: [],

        /** sku列表 */
        sku_list: [],

        /** 商品描述 详情 */
        intro: "",

        /** skuid  */

        sku_id: ""
      },

      /** 临时存储商品相册信息 */
      goodsGalleryList: [],

      /** 用来向组件中传递的数据 */
      skuList: [],

      /** 是否自动生成货号 */
      productSn: false,

      /** 总库存是否可编辑 默认可以 false可编辑 true不可编辑 */
      isEditQuantity: false,

      /** 请求的商品参数组列表 */
      goodsParams: [
        {
          group_id: "",
          group_name: "",
          params: [
            {
              param_id: 0,
              param_name: "",
              param_type: 1,
              param_value: "",
              required: 0,
              option_list: [{ value: 1, label: "" }, { value: 2, label: "" }]
            }
          ]
        }
      ],
      collapseVal: [],

      /** 品牌列表 */
      brandList: [],
      /** 商品图片放大地址 */
      dialogImageUrl: "",

      /** 是否显示放大图片弹框 */
      dialogImage: false,

      /** 是否显示积分配置*/
      isShowExchangeConfig: false,

      /** 商品详情的校验规则 */
      baseInfoFormRule: {
        goods_name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
          {
            whitespace: true,
            message: "商品名称不可为纯空格",
            trigger: "blur"
          },
          { min: 3, max: 255, message: "长度在 3 到 255 个字符", trigger: "blur" }
        ],
        // sn: [
        // 	{ required: true, message: '请输入商品编号', trigger: 'blur' },
        // 	{ validator: checkSn, trigger: 'blur' }
        // ],
        mktprice: [
          { required: true, message: "请输入市场价格", trigger: "blur" },
          { validator: checkMarket, trigger: "blur" }
        ],
        // price: [
        //   { required: true, message: "请输入会员价格", trigger: "blur" },
        //   { validator: checkPrice, trigger: "blur" }
        // ],
        cost: [
          // 成本价 - > 供货价
          { required: true, message: "请输入供货价格", trigger: "blur" },
          { validator: checkCost, trigger: "blur" }
        ],
        original_price: [
          { required: true, message: "请输入集采价格", trigger: "blur" },
          { validator: checkOriginal, trigger: "blur" }
        ],
        weight: [
          { required: true, message: "请输入商品重量", trigger: "blur" },
          { validator: checkWeight, trigger: "blur" }
        ],
        goods_gallery: [
          { validator: checkGoodsGallery, trigger: "change" }
        ],
        quantity: [
          { required: true, message: "请填写总库存", trigger: "blur" },
          { validator: checkQuantity, trigger: "blur" }
        ],
        template_id: [{ validator: checkTplId, trigger: "blur" }],
        exchange_money: [{ validator: checkExchangeMoney, trigger: "blur" }],
        exchange_point: [{ validator: checkExchangePoint, trigger: "blur" }]
      },
      createGoodsButtonLoading: false
    };
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params && to.params.goodsid) {
      this.currentStatus = parseInt(to.params.isdraft) || 0;
      this.activeGoodsId = to.params.goodsid;
      if (this.currentStatus === 1 && to.params.goodsid) {
        this.GET_GoodData();
      }
    } else {
      this.GET_NextLevelCategory();
    }
    next();
  },
  mounted () {
    if (this.$route.params && this.$route.params.goodsid) {
      this.currentStatus = parseInt(this.$route.params.isdraft) || 0;
      this.activeGoodsId = this.$route.params.goodsid;
      if (this.currentStatus === 1 && this.$route.params.goodsid) {
        this.GET_GoodData();
      }
    } else {
      this.GET_NextLevelCategory();
    }
  },
  methods: {
    /** 上架  */
    aboveGoods () {
      this.createGoodsButtonLoading = true;
      if (this.baseInfoForm.activeGoodsId === 0) {
        this.$message.error("严重错误，商城分类Id不可为0");
        this.createGoodsButtonLoading = false;
        return;
      }
      let _params = this.generateFormData(this.baseInfoForm);
      /** 如果规格无变化 则传0 有变化传1 */
      if (!_params.has_changed) {
        _params.has_changed = 0;
      }
      /** 上架 market_enable 1上架0下架*/
      _params.market_enable = 1;
      //
      this.$refs["baseInfoForm"].validate(valid => {
        if (valid) {

          if (this.currentStatus !== 2) {
            if (this.activeGoodsId) {
              console.log("修改正常商品");
              /** 修改正常商品 */
              _params.market_enable = 0;
              API_goods.editGoods(this.activeGoodsId, _params).then(() => {
                this.$message.success("修改商品成功");
                this.$store.dispatch("delCurrentViews", {
                  view: this.$route,
                  $router: this.$router
                });
                this.$router.push({ path: "/goods/goods-list" });
              }).finally(() => { this.createGoodsButtonLoading = false; });
            } else {
              console.log("正常商品上架");
              /** 正常商品上架 */
              _params.price = _params.cost
              API_goods.aboveGoods(_params).then(() => {
                this.$message.success("上架商品成功");
                this.$store.dispatch("delCurrentViews", {
                  view: this.$route,
                  $router: this.$router
                });
                this.$router.push({ path: "/goods/goods-list" });
              }).finally(() => { this.createGoodsButtonLoading = false; });
            }
          } else {
            console.log("上架草稿箱商品成功");
            API_goods.aboveDraftGoods(this.activeGoodsId, _params).then(() => {
              this.$message.success("上架草稿箱商品成功");
              this.$store.dispatch("delCurrentViews", {
                view: this.$route,
                $router: this.$router
              });
              this.$router.push({ path: "/goods/goods-list" });
            }).finally(() => { this.createGoodsButtonLoading = false; });
          }
        } else {
          this.createGoodsButtonLoading = false;
          this.$message.error(
            "表单中存在未填写或者填写有误的地方,已有错误标示，请检查并正确填写"
          );
        }
      });
    },

    /** 搜索下一级 商城商品分类*/
    GET_NextLevelCategory (row, level) {
      this.loading = true;
      const _id = row && row.category_id !== 0 ? row.category_id : 0;
      API_goodsCategory.getGoodsCategoryLevelList(_id, {}).then(response => {
        this.loading = false;
        if (level === 1) {
          this.categoryListLevel2 = response;
          this.categoryListLevel3 = [];
        } else if (level === 2) {
          this.categoryListLevel3 = response;
        } else if (!level) {
          this.categoryListLevel1 = response;
        }
      });
    },

    /** 搜索单个商品信息*/
    GET_GoodData () {
      API_goods.getGoodsExt(this.activeGoodsId, {}).then(response => {
        /** 此处完成商品信息赋值 进行判断如果有值的话 */
        this.baseInfoForm = {
          ...response
        };
        // 集采价如果是空的，那么集采价默认是成本价（供货价） 见 禅道 bug 5507
        if (!this.baseInfoForm.original_price) {
          this.baseInfoForm.original_price = this.baseInfoForm.cost;
        }
        /** 商品相册校验属性 */
        this.baseInfoForm.goods_gallery_list.forEach(key => {
          this.$set(key, "url", key.original);
        });
        this.$nextTick(() => {
          this.setSort();
        });
        this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString();
        this.goodsGalleryList = JSON.parse(
          JSON.stringify(this.baseInfoForm.goods_gallery_list)
        );
        /** 商品规格校验属性  */
        if (
          !this.baseInfoForm.sku_list ||
          !Array.isArray(this.baseInfoForm.sku_list)
        ) {
          this.baseInfoForm.sku_list = [];
        }
        /** 积分相关设置 如果没有积分相关则设置为空 */
        if (
          !this.baseInfoForm.exchange ||
          !this.baseInfoForm.exchange.enable_exchange
        ) {
          this.baseInfoForm.exchange = {
            /** 积分兑换所属分类 */
            category_id: "",
            /** 是否允许积分兑换  1是 0否*/
            enable_exchange: 0,
            /** 兑换所需金额 */
            exchange_money: 0,
            /** 积分兑换使用的积分 */
            exchange_point: 0
          };
        } else {
          this.isShowExchangeConfig =
            this.baseInfoForm.exchange.enable_exchange === 1;
        }
        /** 搜索品牌列表 */
        this.getGoodsBrandList();

        /** 搜索商品参数 */
        this.GET_GoodsParams();

        /** 搜索商品sku信息 */
        API_goods.getGoodsStockList(this.activeGoodsId, {}).then(response => {
          /** 构造临时规格数据 */
          this.skuList = response.map(key => {
            if (key && key.spec_list && Array.isArray(key.spec_list)) {
              const spec_list = key.spec_list.map(item => {
                let {
                  spec_id,
                  spec_image,
                  spec_type,
                  spec_value,
                  spec_value_id,
                  spec_name
                } = item;
                return {
                  spec_id,
                  spec_image,
                  spec_type,
                  spec_value,
                  spec_value_id,
                  spec_name
                };
              });
              let { cost, quantity, sn, weight, sku_id } = key;
              // const price = key.price
              const price = key.supplier_price;
              return { cost, price, quantity, sn, weight, sku_id, spec_list };
            }
          });
        });
      });
    },

    /** 搜索商品参数 */
    GET_GoodsParams (next = null) {
      if (this.activeGoodsId && !next) {
        API_goods.getEditGoodsParams(
          this.baseInfoForm.category_id,
          this.activeGoodsId
        ).then(response => {
          this.loading = false;
          this.goodsParams = response;
          this.collapseVal = this.goodsParams.map(key => {
            if (key.group_id) {
              return key.group_id;
            }
          });
          if (!response || response.length <= 0) {
            return;
          }
          this.baseInfoForm.goods_params_list = [];
          this.goodsParams.forEach(key => {
            if (key && key.params) {
              key.params.forEach(elem => {
                this.baseInfoForm.goods_params_list.push(elem);
              });
            }
          });
        });
      } else {
        API_goods.getGoodsParams(this.baseInfoForm.category_id).then(
          response => {
            this.loading = false;
            this.goodsParams = response;
            this.collapseVal = this.goodsParams.map(key => {
              if (key.group_id) {
                return key.group_id;
              }
            });
            if (!response || response.length <= 0) {
              return;
            }
            this.baseInfoForm.goods_params_list = [];
            this.goodsParams.forEach(key => {
              if (key && key.params) {
                key.params.forEach(elem => {
                  this.baseInfoForm.goods_params_list.push(elem);
                });
              }
            });
          }
        );
      }
    },

    /** 搜索商品品牌列表 */
    getGoodsBrandList () {
      API_goods.getBrandList(this.baseInfoForm.category_id, {}).then(
        response => {
          this.brandList = response.map(item => {
                item.brand_id = Number(item.brand_id)
              return item
            })
        }
      );
    },
    /** 文件列表  图片个数 校验*/
    handleExceed (files, fileList) {
      this.$message.warning(`最多上传5张图`);
    },
    /** 文件列表移除文件时的钩子  图片删除校验*/
    handleRemove (file, fileList) {
      this.baseInfoForm.goods_gallery_list.forEach((key, index) => {
        if (key.img_id !== -1) {
          if (key.img_id === file.img_id) {
            this.baseInfoForm.goods_gallery_list.splice(index, 1);
          }
        } else {
          if (key.name === file.response.name) {
            this.baseInfoForm.goods_gallery_list.splice(index, 1);
          }
        }
      });
      if (fileList.length <= 0) {
        this.baseInfoForm.goods_gallery_list = [];
        this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString();
      }
      this.$refs.baseInfoForm.validateField("goods_gallery");
    },

    /** 文件列表上传成功时的钩子  上传成功校验 */
    handleSuccess (response, file, fileList) {
      this.baseInfoForm.goods_gallery_list.push({
        img_id: -1,

        original: response.url,

        url: response.url,

        sort: 0,

        name: response.name
      });
      this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString();
      this.$refs["baseInfoForm"].validateField("goods_gallery");
      this.$nextTick(() => {
        this.setSort();
      });
    },

    /** 点击已上传的文件链接时的钩子 放大 */
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogImage = true;
    },

    /** 拖拽改变图片顺序 */
    setSort () {
      const el = document.querySelectorAll(
        "div.avatar-uploader > ul.el-upload-list--picture-card"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) { },
        onEnd: evt => {
          let temp = this.baseInfoForm.goods_gallery_list[evt.oldIndex];
          this.baseInfoForm.goods_gallery_list[
            evt.oldIndex
          ] = this.baseInfoForm.goods_gallery_list[evt.newIndex];
          this.baseInfoForm.goods_gallery_list[evt.newIndex] = temp;
        }
      });
    },

    /** 图片上传之前的校验  */
    beforeAvatarUpload (file) {
      const isType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isType) {
        this.$message.error("上传头像图片只能是 JPG/JPEG/PNG 格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传商品相册图片大小不能超过 1MB!");
      }

      /** 重复校验*/
      const isExit = this.baseInfoForm.goods_gallery_list.some(key => {
        return file.name === key.name;
      });
      if (isExit) {
        this.$message.error("图片已存在");
      }

      return isType && isLt1M && !isExit;
    },

    /** 构造表单数据 */
    generateFormData (data) {
      let _params = {
        ...data
      };
      // 删除不必要的表单数据
      delete _params.original;
      delete _params.exchange_category_id;
      delete _params.exchange_money;
      delete _params.exchange_point;
      delete _params.gallery_list;
      delete _params.goods_gallery;
      if (_params.goods_gallery_list) {
        _params.goods_gallery_list.forEach(key => {
          delete key.name;
          delete key.big;
          delete key.goods_id;
          delete key.isdefault;
          delete key.small;
          delete key.status;
          delete key.thumbnail;
          delete key.tiny;
          delete key.uid;
          delete key.url;
        });
      }
      /** 积分商品数据 */
      if (!this.isShowExchangeConfig) {
        _params.goods_type = "NORMAL";
        _params.exchange.enable_exchange = 0;
      } else {
        _params.goods_type = "POINT";
        _params.exchange.exchange_money = parseFloat(
          _params.exchange.exchange_money
        );
      }
      /** 运费模板 */
      _params.template_id = _params.template_id || 0;
      return _params;
    }
  }
};
</script>

<style lang="scss" scoped>
/*选择商品品类*/
.content-goods-publish {
  padding: 15px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #ddd;
  background: none repeat 0 0 #fff;
  border-radius: 5px;

  /*商品品类*/
  .goods-category {
    text-align: left;
    padding: 10px;
    background: #fafafa;
    border: 1px solid #e6e6e6;

    ul {
      padding: 8px 4px 8px 8px;
      list-style: none;
      width: 300px;
      background: none repeat 0 0 #fff;
      border: 1px solid #e6e6e6;
      display: inline-block;
      letter-spacing: normal;
      margin-right: 15px;
      vertical-align: top;
      word-spacing: normal;
      overflow-x: scroll;
      height: calc(100vh - 300px);

      li {
        line-height: 20px;
        padding: 5px;
        cursor: pointer;
        color: #333;
        font-size: 12px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  /** 当前品类被选中的样式 */
  .activeClass {
    background-color: #d9edf7;
    border: 1px solid #bce8f1;
    color: #3a87ad;
  }

  /*!*当前选择的商品品类文字*!*/
  .current-goods-category {
    text-align: left;
    padding: 10px;
    width: 100%;
    border: 1px solid #fbeed5;
    color: #c09853;
    background-color: #fcf8e3;
    margin: 10px auto;
    padding: 8px 35px 8px 14px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-weight: bold;
  }
}

/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;

  .el-form-item {
    width: 100%;
    text-align: left;
  }
}

/*平铺*/
div.base-info-item>div {
  margin-left: 5%;
}

div.base-info-item {
  h4 {
    margin-bottom: 10px;
    padding: 0 10px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    font-weight: bold;
    color: #333;
    font-size: 14px;
    line-height: 40px;
    text-align: left;
  }

  /** 审核信息-拒绝原因 */
  .auth-info {
    color: red;
  }

  .el-form-item {
    width: 30%;
    min-width: 300px;
  }

  .goods-name-width {
    width: 50%;
    min-width: 300px;
  }

  .el-form-item__content {
    margin-left: 120px;
    text-align: left;
  }

  p.goods-group-manager {
    padding-left: 7.5%;
    text-align: left;
    color: #999;
    font-size: 13px;
  }

  /*teatarea*/
  /deep/ .el-textarea {
    width: 150%;
  }

  .seo-text {
    width: 150%;
  }
}

/*折叠面板*/
.el-collapse-item {
  /deep/ .el-collapse-item__header {
    text-align: left;
    background-color: #f8f8f8;
    padding: 0 10px;
    font-weight: bold;
    color: #333;
    font-size: 14px;
  }

  .el-form-item {
    margin-left: 5%;
    width: 25%;
  }

  /deep/ .el-form-item__content {
    margin-left: 120px;
    text-align: left;
  }

  p.goods-group-manager {
    padding-left: 12%;
    text-align: left;
    color: #999;
  }

  /deep/ .el-collapse-item__content {
    padding: 10px 0;
    text-align: left;
  }
}

/*商品描述*/
.goods-intro {
  line-height: 40;
}

/** 底部步骤 */
.footer {
  // width: calc(100% - 280px);
  padding: 10px;
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  // left: 280px;
  text-align: center;
  z-index: 999;
  border-top: 1px solid #eee;
  left: 13.2%;
  right: 2%;
}

/*图片上传组件第一张图设置封面*/
.goods-images {
  /deep/ li.el-upload-list__item:first-child {
    position: relative;
  }

  /deep/ li.el-upload-list__item:first-child:after {
    content: "封";
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    left: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    padding-top: 6px;
    background: #13ce66;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
  }
}
</style>
